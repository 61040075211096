<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    viewBox="0 0 16.244 15.296"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="3"
      d="m.91 9.03 5.25 4.007s4.05-7.507 9.1-11.907"
    />
  </svg>
</template>
